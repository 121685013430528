import { createApp } from 'vue'
import App from './App.vue';
const tzConfig = require('../tz.config');
import { setLocal, setToken, getAppId, getLocal } from '../src/utils/index'

console.log(tzConfig)

// Global CSS styles
import '@/assets/css/style.css'

import {
  ActionSheet,
  Image as TzImage,
  Step, Steps,
  Collapse, CollapseItem,
  RadioGroup, Radio,
  Form, Field, CellGroup,
  Popup, Picker, DatetimePicker, Uploader, Tabbar, TabbarItem, Button,
  Col, Row, List, NavBar,
  PullRefresh, Cell, Empty, Dialog, Loading, Icon, Toast, Grid, GridItem, Popover, Badge, Sticky, Switch, ShareSheet, Checkbox, CheckboxGroup, Search
} from 'tzui-vue';

import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'

import routes from '@/routes/index';



// change language
import { Locale } from 'tzui-vue';
import enUS from 'tzui-vue/es/locale/lang/en-US';

Locale.use('en-US', enUS);

const myApp = createApp(App);

// myApp.component('v-chart', )


myApp.use(ActionSheet);
myApp.use(TzImage);
myApp.use(Form);
myApp.use(Field);
myApp.use(CellGroup);
myApp.use(Popup);
myApp.use(Picker);
// myApp.use(DropdownMenu);
// myApp.use(DropdownItem);
myApp.use(TabbarItem);
myApp.use(Tabbar);
myApp.use(Button);
myApp.use(Col);
myApp.use(Row);
myApp.use(List);
myApp.use(NavBar);
myApp.use(Cell);
myApp.use(PullRefresh);
myApp.use(Empty);
myApp.use(Dialog);
myApp.use(Loading);
myApp.use(Step);
myApp.use(Steps);
myApp.use(Collapse);
myApp.use(CollapseItem);
myApp.use(Radio);
myApp.use(RadioGroup);
myApp.use(DatetimePicker);
myApp.use(Uploader);
myApp.use(Icon);
myApp.use(Toast);
myApp.use(Grid);
myApp.use(GridItem);
myApp.use(Popover);
myApp.use(Badge);
myApp.use(Sticky);
myApp.use(Switch);
myApp.use(ShareSheet);
myApp.use(Checkbox);
myApp.use(CheckboxGroup);
myApp.use(Search);
// myApp.use(TzChat);


// let base = location.href.split(location.origin)[1].split('/');
// let appId = base[2];
let appId = tzConfig.appId;


var queryParams = new URLSearchParams(location.search);
console.log("Query params before------------------", queryParams);

const scopeId = queryParams.get('scopeId') || '';
setLocal('app-currencyCode', 'XAF');
let language = queryParams.get('lang') || getLocal('app-lang') || 'en';
const token = queryParams.get('token') || "";
const ftoken = queryParams.get('ftoken') || '';


myApp.config.globalProperties.$tzAppId = appId;
setLocal('tz-ms-appId', appId);

// console.log("Token before------------------", token)
if (token) {
  setToken(token);
  myApp.config.globalProperties.$tzToken = token;

}

if(scopeId){
  myApp.config.globalProperties.$tzScopeId = scopeId;
}

if(!language || language != 'fr'){
  language = 'en';
}

if (language) {
  setLocal('app-lang', language)
}
if (ftoken) {
  setLocal('app-ftoken', ftoken)
}

const router = createRouter({
  history: createWebHistory(process.env.NODE_ENV == 'development' ? '' : '/pay'),
    routes, // short for `routes: routes`
});

router.beforeEach((to, from) => {
  Toast.loading({
    message: language.includes('en')? 'Loading...': 'Chargement...',
    forbidClick: true,
    duration: 0
  });
})
router.afterEach((to, from) => {
  Toast.clear();
})

const fr = require('@/assets/languages/fr.json');
const en = require('@/assets/languages/en.json');


if(language.includes('fr')){
  myApp.config.globalProperties.$t = e=> {
    if(!fr[e]){
      console.warn('French translation not found for: '+e)
    }
    return fr[e] || en[e] || e
  }
}else{
  myApp.config.globalProperties.$t =  e=> {
    if(!en[e]){
      console.warn('English translation not found for: '+e)
    }
    return en[e] || e
  }
}


// add router
myApp.use(router);

myApp.mount('#app')
