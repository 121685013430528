<template>
  <div class="home-container">
    <div class="header">
      <div class="logo-container" v-cloak>
        <div class="logo">
          <tz-image width="90" src="logo.png" />
        </div>
        <div class="mp-title">
         {{lang.tranzakPaymentGateway}}
        </div>
        <!-- <span class="slogan">{{ appInfo.slogan }}</span> -->
      </div>
      <div
        class="bg-container"
        :style="{ backgroundImage: 'url(' + backgroundImage + ')' }"
      ></div>
      <div class="color-overlay"></div>
      <!-- This is the curvy border at the bottom -->
      <div class="bordered-top"></div>
    </div>
    <div class="home-content">
      <div class="grid-container">

        <!-- LOOP THROUGH AVAILABLE SOLUTIONS FROM DATA -->

        <tz-grid :gutter="10" :column-num="2" direction="horizontal">
          <tz-grid-item v-for="solution in solutions" :key="solution.path">
            <div @click="triggerAction(solution)" style="cursor: pointer;">
              <tz-icon size="36" :name="solution.icon" />
              <div class="title">{{ lang[solution.name] }}</div>
            </div>
          </tz-grid-item>
        </tz-grid>

        <tz-popup
          v-model:show="showRefIdForm"
          round
          closeable
          position="bottom"
          :style="{ height: '45%', paddingTop: '20px'}"
        >
        <div>
          <h2 style="text-align: center; padding-bottom: 20px; border-bottom: 1px solid #ccc;">
            {{ lang[selectedAction.desc]}}
          </h2>
          <div style="padding-top: 50px">
            <tz-cell-group inset>
              <tz-field
                ref="ref"
                :autofocus="true"
                v-model="providedId"
                name="ref"
                :placeholder="lang[selectedAction.desc]"
              />
            </tz-cell-group>
            <div style="margin: 16px;">
              <tz-button round block type="primary" native-type="submit" @click="submit()">
                {{lang.continue}}
              </tz-button>
            </div>
          </div>
        </div>
        </tz-popup>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { Toast, Dialog } from 'tzui-vue'
import { getAppInfo, GetMerchantUserInfo } from "@/api/common";
import { getAppId, getLocal, setLocal } from "../../utils";
export default {
  data() {
    return{
      showRefIdForm: false,
      providedId: '',
      selectedAction: {},
      lang: {},
      backgroundImage: '',
      displayName: null,
      solutions: [
        {name: 'invoices', path: 'invoice', icon: 'description', desc: 'enterInvoiceId' },
        {name: 'paymentLink', path: 'payment-link', icon: 'paid', desc: 'enterPaymentLinkId'},
      ]
    }
  },
  mounted () {
    const language = getLocal('app-lang') || 'en';
    this.lang = language.includes('fr')? require('@/assets/languages/fr.json'): require('@/assets/languages/en.json');

  },
  methods:{
    submit(){
      if(!this.providedId.length){
        Toast({
          duration: 3000,
          message: this.lang[this.selectedAction.desc],
        });
        this.$refs.ref.focus();
        return 0;
      }
      this.goTo(`/${this.selectedAction.path}/${this.providedId}`);
    },
    triggerAction(action){
      this.selectedAction = action;
      this.showRefIdForm = true;
    },
    getMerchantUserInfo(){
         GetMerchantUserInfo().then(res=>{
            if(res.success && res.data){
                const user = res.data.userInfo;
                let displayName = user.phone || '';
                if(user.displayName.length){
                    displayName = user.displayName;
                }
                else if( user.firstName.length || user.lastName.length){
                    displayName = `${user.firstName} ${user.lastName}`;
                }
                const currency = res.data.primaryCurrencyCode ? res.data.primaryCurrencyCode: 'XAF';
                setLocal('app-currencyCode', currency);
                setLocal('displayName', displayName);
                this.displayName = displayName;
                setLocal('appInfo', {...res.data, displayName});
            }
        }).catch(err=>{
            console.error("Error loading merchant user info =========>",err);
        })
    },
  },
  setup() {
    const router = useRouter();
    const goTo = (url) => {
      router.push(url);
    };

    return {
      goTo,
    };
  },
};
</script>
<style lang="less" scoped>
.home-container {
  .header {
    position: relative;
    // height: 300px;
    .bg-container {
      widows: 100%;
      height: 230px;
      background-image: url("http://fms.tranzak.me/cdn/ia/xp6x3id05dni7gwn_33eyajvvabmzq0102620.40621823688.jpg");
      background-repeat: no-repeat;
      background-size: cover;
    }
    .mp-title{
      padding-top: 10px;
      font-size: 27.5px;
      text-align: center;
      margin-bottom: 10px;
      color: rgb(255, 255, 255);
    }

    .logo-container {
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      justify-content: center;
      position: absolute;
      padding-top: 30px;
      left: 0;
      z-index: 2;
      width: 100%;
      .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        background-color: rgb(255, 255, 255);
        // box-shadow: 2px 2px 1px 1px rgba(0, 0, 0, 0.4);
        border-radius: 8px;
      }
      .slogan {
        color: rgb(255, 255, 255);
        font-size: smaller;
        padding-top: 14px;
        font-style: italic;
        font-weight: lighter;
        opacity: 0.8;
      }
    }
    .color-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--tz-primary-color);
      opacity: 0.8;
    }
    .bordered-top {
      width: 100%;
      height: 30px;
      position: relative;
      overflow: hidden;
      position: absolute;
      bottom: 0;

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100%;
        border-radius: 0 0 30px 30px;
        box-shadow: 0px 300px 0px 300px #ffffff;
      }
    }
  }
  .home-content {
    position: relative;
    height: 100%;
    padding-top: 4px;

    .grid-container {
      // background-color: rgb(250, 250, 250);
      // height: 60vh;
      padding: 20px 5% 0 5%;
      margin: auto;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 1px;
      grid-row-gap: 14px;
      align-content: flex-start;
      align-items: center;
      justify-items: center;

      .tz-grid-item div {
        width: 100%;
        padding: 4px;
        color: #ffffff;
        text-align: center;
        border-radius: 8px !important;
        background-color: var(--tz-primary-color) !important;
      }
      .grid-item-btn {
        width: 128px;
        height: 100px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        border: 1px solid rgba(120, 120, 120, 0.1);
        background-color: #fff;
        color: var(--tz-primary-color);
        .icon-wrapper {
            border-radius: 10px 10px 0 0;
            color: #fff;
            background-color: var(--tz-primary-color);
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0.75;
        }
        &:hover {
          cursor: pointer;
        }
        .title {
          font-size: 11px;
          font-weight: bold;
          color: rgb(83, 83, 83);
          width: 100%;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
</style>