export const getLocal = (name) => {
  let result = localStorage.getItem(name);

  return result && result != "undefined" ? JSON.parse(result) : undefined;
}


const dayjs =  require('dayjs');

var locale = getLocal('app-lang');
if(locale && locale.includes('fr')){
  locale = 'fr';
}else{
  locale = 'en-gb'
}
require('dayjs/locale/' + locale);
dayjs.locale(locale);
console.log(dayjs.locale(locale))

var globalAppId;
var globalToken;
var globalAppKey;

export const dateBefore  = e=> dayjs().isBefore(dayjs(e));

export const setLocal = (name, value) => {
  localStorage.setItem(name, JSON.stringify(value));
}

export const clearLocal = (name) => {
  localStorage.setItem(name, "");
}

export const getAppId = () => {
  // const regex = /\/tzmp\/(\w+)/;
  // const found = location.pathname.match(regex);

  // if (found && found.length >= 2) {
  //   return found[1];
  // }

  return "apbcz5mppe3bj6";
}

export const toFinancialNumber = (x = 0, val = 2) => {
  if (!x) return "";
  if (x > 1) return Number.parseFloat(x).toFixed(val);

  return Number.parseFloat(x).toFixed(1 - Math.floor(Math.log(x) / Math.log(10)));
  // alternative way below
  //var r = n.toFixed(20).match(/^-?\d*\.?0*\d{0,2}/)[0];
};

export const isValidCmNumber = (number) => {
  return /^6[256789]\d{7}$/.test(number);
}

export const isMtn = function(phone) {
  return /^67/.test(phone) || /^65[0-4]/.test(phone) || /^68[0-3]/.test(phone);
}

export const isOrange = function(phone) {
  return /^69/.test(phone) || /^65[5-9]/.test(phone) || /^68[6-9]/.test(phone);
}

export const formatCurrency = (value, currency = 'XAF', language = 'en-US') =>{
  //currency eg USD
  //language eg fr-FR, en-US
  return new Intl.NumberFormat(language, { style: 'currency', currency: currency }).formatToParts(value).map(
      p => p.type != 'literal' && p.type != 'currency' ? p.value : ''
  ).join('');
};

export const  formatDate = (value = '') => {
  if(!value.toLowerCase().includes('z')){
    value = new Date(value);
    value.setUTCHours(value.getHours(),value.getMinutes());
    value.setUTCFullYear(value.getFullYear());
    value.setUTCMonth(value.getMonth());
  }
  return dayjs(value).format('ddd, MMM D, YYYY h:mm:ss A') || ''
};

export const  formatShortDate = (value = '') => {
  if(!value.toLowerCase().includes('z')){
    value = new Date(value);
    value.setUTCHours(value.getHours(),value.getMinutes());
    value.setUTCFullYear(value.getFullYear());
    value.setUTCMonth(value.getMonth());
  }
  return dayjs(value).format('DD:MM:YYYY') || ''
};

export const graphDate = (value = '') => {
  var calendar = require('dayjs/plugin/calendar')
  dayjs.extend(calendar);

  if(!value.toLowerCase().includes('z')){
    value = new Date(value + 'z');
    // value.setUTCHours(value.getHours(),value.getMinutes());
    // value.setUTCFullYear(value.getFullYear());
    // value.setUTCMonth(value.getMonth());
  }
  const date  = dayjs(value);
  const oneDay = 60 *60 * 24 * 1000;
  const days = (date.diff(new Date()) * -1 / oneDay);
  if(days <= 1){
    return dayjs(value).calendar(null, {sameDay: 'h:mm A'})
  }else{
    return dayjs(value).format('D-M-YYYY h:mm A')
  }
};

export const convertToUTC = (date, time = '00:00:00') => {
  return new Date(new Date(date + ' ' + time)).toISOString()
};


export const  toUtcDate = (value = '') => {
  if(!value.toLowerCase().includes('z')){
    if(value == ''){
      value = new Date();
    }else{
      value = new Date(value);
    }
    value.setUTCHours(value.getHours(),value.getMinutes());
    value.setUTCFullYear(value.getFullYear());
    value.setUTCMonth(value.getMonth());
  }
  return value.toISOString();
};

// NEW IMPORTS FROM TREC MP MODULES


export const getToken = () => {
  // console.log("GET TOKEN================1====");
  if (globalToken) return globalToken;
  // console.log("GET TOKEN================2====");
  const appId = getAppId();
  console.log('static appId: ', appId)
  // console.log("GET TOKEN================appId====", appId);

  globalToken = getLocal('tz-payment-token-' + appId);

  return globalToken;

  // return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkaXNwbGF5TmFtZSI6IiIsIm9wZW5JZCI6InIzcDR6ZWpxamlzanJ5IiwicGhvbmUiOiIrMjM3NjcxOTIyMTYzIiwic2NvcGVJZCI6InVnbXN4Y2p1NzM0NmZ2IiwidXNlcklkIjoiNmE0NGVjNmNjNGYyNDJhZGJlYTcwNzE1YWFlODk3NzQiLCJpYXQiOjE2NDQyNjA1NjAsImV4cCI6MTY3NTc5NjU2MH0.qYQJg0mXh8EQLvt-hVKps15UBsfYOnC232Gvc638Qd4';
}

export const setToken = (token, appId = getAppId()) => {
  console.log("SET TOKEN================appId====", appId, token);
  setLocal('tz-payment-token-' + appId, token);

  globalToken = token;

  console.log("SET TOKEN================globalToken====", globalToken);
}

export const getAppKey = () => {

  if(globalAppKey) return globalAppKey;

  return "0C41825476FF44D0BF827AFB49969C2C"; //TODO: Change this key.

}

export const getUUID = () => {
  const id =
    Math.random()
      .toString(36)
      .substring(5) +
    Date.now() +
    Math.floor(1000000 * Math.random()).toString();

  return id;
}

export const getDistinctId = () => {
  let xwsGlobalDistinctId = localStorage.getItem("tzDistinctId");

  if (!xwsGlobalDistinctId) {
    xwsGlobalDistinctId = getUUID();
    localStorage.setItem("tzDistinctId", xwsGlobalDistinctId);
  }


  return xwsGlobalDistinctId;
}

export const getPlatformId = () => {
  return "g7o84wzypf64";
}

export function getRunningEnv() {
  switch (process.env.NODE_ENV) {
    case "production":
      return 'PROD';

    case "staging":
      return 'STAG';

    default:
      return 'DEV';
  }
}

export const getAcePlatform = () => 'apeuqsw4g9rfbx'