import axios from '../utils/axios'

/*
This is only for apis that are used for functions that are globally needed
*/
// Get this app's info
const baseUrl = process.env.NODE_ENV == 'production'? 'https://api.tranzak.me': 'https://staging-api.tranzak.me';
export function getAppInfo(params) {
  return axios.get(baseUrl + '/aa038/v1/mps/details/' + params);
}

// Get running academic year
export function getCurrentAcademicYear() {
  return axios.get(baseUrl + '/v1/academicYr/current');
}

// Get the list of students you have paid before
export function getPrevPayment() {
  return axios.get(baseUrl + '/v1/fee/payer/students');
}

// create transactions
export function createTransaction (params) {
  return axios.post(baseUrl + '/v1/transaction', params);
}


// get transactions
export function getMyTransactions (params) {
  return axios.post(baseUrl + '/v1/transaction/all', params);
}

// Check for existing transactions
export function checkMyTransaction (params) {
  return axios.post(baseUrl + '/v1/transaction/verify', params);
}


// Pay Edu Fees
export function payFee (params) {
  return axios.post(baseUrl + '/v1/fee/new', params);
}

// Update Edu Fees
export function updateFee (params) {
  return axios.put(baseUrl + '/v1/fee/update', params);
}

// Get specific Edu fees
export function getSpecific (params) {
  return axios.post(baseUrl + '/v1/fee/unique', params);
}

// Get all latest Edu fees
export function getLatestFees (params) {
  return axios.get(baseUrl + '/v1/fee/latest', params);
}

// Create a request to purchase a service
export function requestService (params) {
  return axios.post(baseUrl + '/edu/v1/onboarding/application', params);
}

// Update a request to purchase a service
export function updateRequestedService (params) {
  return axios.put(baseUrl + '/edu/v1/onboarding/application', params);
}

// Query to check if requests aren't pending
export function queryRequestedService (params) {
  return axios.post(baseUrl + '/edu/v1/onboarding/user/application', params);
}
// Upload image
export function uploadImage (params) {
  return axios.post(baseUrl + '/cb068/v1/file/upload/admin/image', params);
}
// Upload image
export function GetMerchantUserInfo (params = '') {
  return axios.post(baseUrl + '/ba021/v1/services/userInfo', params);
}

export function getBusinessAccountInfo(params) {
  return axios.get(baseUrl + '/ba021/v1/business-account/orgAccount-public/' + params);
}
