
import HomeView from '@/views/Home/index.vue';

const NotFound = {
  template: '<div style="text-algin:center; margin: 100px 20px"><h4>Page Not Found</h4></div>',
}

const routes = [
  {
    path: '/', component: HomeView
  },
  {
    path: '/invoice/:id', component: () =>
      import(/* webpackChunkName: "invoices" */ '@/views/Invoice/DisplayInvoice.vue'),
      name: 'invoice',
  },
  {
    path: '/acknowledgement', component: () =>
      import(/* webpackChunkName: "acknowledgement" */ '@/views/Acknowledgement/Acknowledgement.vue'),
      name: 'acknowledgement',
  },
  {
    path: '/payment-link/:id', component: () =>
      import(/* webpackChunkName: "paymentLink" */ '@/views/PaymentLink/DisplayLink.vue'),
      name: 'payment-link',
  },
  { path: '/:pathMatch(.*)*', name: 'NotFound', redirect: '/' },
]

export default routes;