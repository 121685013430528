import axios from 'axios'
import { Toast, Dialog } from 'tzui-vue'
import { getAppId, getLocal, getAppKey, getToken, getDistinctId } from '@/utils/index'
var emitter = require('tiny-emitter/instance');
const language = getLocal('app-lang') || 'en'
const lang = language.includes('fr')? require('@/assets/languages/fr.json'): require('@/assets/languages/en.json');
console.log(lang)

let pendingRequestsNumber = 0;
var queryParams = new URLSearchParams(location.search);
const token = getLocal('tz-payment-token') || ''

// console.log("token from request file: =========>>  :", token)

const baseURL = process.env.NODE_ENV == 'production' ? 'https://api.tranzak.me' : 'https://staging-api.tranzak.me' //'http://api.tranzak.me'
// // const baseURL = "http://localhost:6110" //'http://api.tranzak.me'
// // axios.defaults.withCredentials = true
// axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'
// axios.defaults.headers['X-App-ID'] = getAppId();
// axios.defaults.headers.post['Content-Type'] = 'application/json'
// axios.defaults.headers['Authorization'] = `Bearer ${token}`;


// import eventBus from "./event-bus";

var instance = axios.create({
  baseURL: '',
  timeout: 30000
});

// // Add a request interceptor
// instance.interceptors.request.use(function (config) {
//   trackPendingRequest('req');

//   return config;
// }, function (error) {
//   return Promise.reject(error);
// });

// instance.interceptors.response.use(res => {
//   trackPendingRequest('res');

//   if (typeof res.data !== 'object') {
//     Toast.fail('Error！')
//     return Promise.reject(res)
//   }
//   if(res.data.errorMsg){
//     Dialog.alert({
//       title: lang.alert,
//       message: res.data.errorMsg
//     })
//   }

//   return res.data;
// }, error => {
//   trackPendingRequest('res');
//   return Promise.reject(error);
// })



const trackPendingRequest = (type) => {
  // console.log("------------ pending requests ", pendingRequestsNumber);

  if (type == 'req') {
    pendingRequestsNumber++;

    if (pendingRequestsNumber == 1) { // only show once
      // show loader
      Toast.loading({
        message: lang.loading,
        forbidClick: true,
        duration: 0
      });
      // emitter.emit('bt-toggle-loader', false);
    }

  } else {
    pendingRequestsNumber--;

    if (pendingRequestsNumber == 0) {
      //hide loader
      Toast.clear();
      // emitter.emit('bt-toggle-loader', true);
    }
  }
};

instance.interceptors.request.use(function (param) {

  trackPendingRequest('req');

  if (param && param.data && param.data.isFormData) {
    var postData = new FormData();
    if (param.data) {
      var keys = Object.keys(param.data);
      for (var i = 0; i < keys.length; i++) {
        postData.append(keys[i], param.data[keys[i]]);
      }
    }
    param.data = postData;
  }
  return param;
});

instance.interceptors.response.use(res => {

  trackPendingRequest('res');

  if (typeof res.data !== 'object') {
    Toast.fail('Error！')
    return Promise.reject(res)
  }

  if(res.data.errorMsg){
    Dialog.alert({
      title: lang.alert,
      message: res.data.errorMsg
    })
  }

  return res.data;
}, error => {
  trackPendingRequest('res');
  return Promise.reject(error);
})

var processResponse = function (response, path, data, extra) {
  if (response && response.data && (response.data.errorMsg || response.data.errorCode)) {
    if (response.data.errorCode == 40001 || response.data.errorCode == 40002) {
      Toast.fail('Auth Error')
    } else {
      Toast.fail(response.data.errorMsg)
    }

    return;
  }

  return response;
};

var processError = function (responseError, path) {
};

const getRequestConfig = function (params, extra = {}) {
  let env = process.env.NODE_ENV;
  const exitingToken = getToken();
  const requestToken = "Bearer " + (exitingToken ? exitingToken.replaceAll('"', '') : '');

  // config
  return {
    baseURL: '',
    headers: {
      "X-Force-Object": "0",
      "X-Platform": "mp",
      "X-System": "",
      "X-Brand": "",
      "X-Model": "",
      "X-Language": language,
      "X-Network": "",
      "X-Lat": "",
      "X-Lon": "",
      "Authorization": requestToken,
      "X-Nonce": "",
      "X-App-ID": getAppId(),
      // "X-Platform-Id": getAppId(),
      "X-App-Key": getAppKey(),
      "X-App-Market": "",
      "X-Tz-Distinct-Id": getDistinctId(),
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json'
    }, // `params` are the URL parameters to be sent with the request // `headers` are custom headers to be sent
    // Must be a plain object or a URLSearchParams object
    // params: {},
    data: {},
    timeout: extra.timeout || 60 * 1000, // You may modify the headers object. // 默认30秒。可以按照具体业务修改timeout 值
    responseType: "json"
  }; // default
};

const unkownResult = {
  err: {
    ec: "0",
    em: "Unknown Issue"
  },
  isUnkownError: true
};

export default {
  post: function (path, data, extra = { hideLoadingSpinner: false }) {
    return instance.post(path, data, getRequestConfig({}, extra)).then(response => {
      let result = processResponse(response, path, data, extra);
      if (result && result.err) {
        throw result;
      } else if (result) {
        return result;
      } else {
        return {};
      }
    })
      .catch(err => {
        console.log("-----||||||||||||||err--api||||||||||------>>>--", err);
        // requestLoaderPostProcessor(extra.hideLoadingSpinner);
        throw err;
      });
  },
  put: function (path, data, extra = { hideLoadingSpinner: false }) {
    return instance.put(path, data, getRequestConfig({}, extra)).then(response => {
      let result = processResponse(response, path, data, extra);
      if (result && result.err) {
        throw result;
      } else if (result) {
        return result;
      } else {
        return {};
      }
    })
      .catch(err => {
        console.log("-----||||||||||||||err--api||||||||||------>>>--", err);
        // requestLoaderPostProcessor(extra.hideLoadingSpinner);
        throw err;
      });
  },
  get: function (path, extra = { hideLoadingSpinner: false }) {
    // requestLoaderPreProcessor(extra.hideLoadingSpinner);
    return instance
      .get(path, getRequestConfig({}, extra))
      .then(response => {
        let result = processResponse(response, path, null, extra);
        if (result.err) {
          throw result;
        } else {
          return result;
        }
      })
      .catch(() => {
        processError(unkownResult, path);
        // requestLoaderPostProcessor(extra.hideLoadingSpinner);
        throw unkownResult;
      });
  }
}